import React, { useCallback, useEffect, useState } from "react";
import { Text, Input, Select, Option, Button, Tooltip } from "@appsmith/ads";
import {
  createMessage,
  WORKFLOW_SCHEDULE_FORM_CTA_SUBMIT,
  WORKFLOW_SCHEDULE_TRANSFORMATION_FAILED,
} from "ee/constants/messages";
import {
  useForm,
  type SubmitHandler,
  Controller,
  type Control,
} from "react-hook-form";
import type { WorkflowSchedulerFormDataType } from "./types";
import { WokrflowSchedulerFormFields } from "./types";
import { cronFormConfig, allTimeZoneOpts } from "./constants";
import styled from "styled-components";
import cronstrue from "cronstrue";
import { debounce } from "lodash";

export interface FormProps {
  defaultValues: Partial<WorkflowSchedulerFormDataType>;
  isWorkflowScheduleSaving: boolean;
  triggerScheduleSave: (data: WorkflowSchedulerFormDataType) => void;
}

const LabelTextWithTooltip = styled(Text)`
  cursor: help;
`;

const TransformedCronStringText = styled(Text)<{ isValid: boolean }>`
  padding: 12px;
  background-color: var(--ads-v2-color-bg-subtle);
  border-radius: 4px;
  color: ${(props) =>
    props.isValid
      ? "var(--ads-v2-color-gray-600)"
      : "var(--ads-v2-color-gray-400)"};
`;

const RequiredFieldWrapper = styled.span`
  color: var(--ads-v2-color-fg-error);
`;

function InputFormComponent({
  control,
  dataTestId,
  fieldName,
}: {
  control: Control<WorkflowSchedulerFormDataType, unknown>;
  dataTestId: string;
  fieldName: WokrflowSchedulerFormFields;
}) {
  const { label, placeholder, tooltip, validationRules } =
    cronFormConfig[fieldName];

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onBlur, onChange, value }, fieldState }) => {
        return (
          <div className="flex flex-col w-[120px]">
            <Tooltip content={createMessage(tooltip)}>
              <div className="flex gap-0.5">
                <LabelTextWithTooltip>
                  {createMessage(label)}
                </LabelTextWithTooltip>
                <RequiredFieldWrapper>*</RequiredFieldWrapper>
              </div>
            </Tooltip>
            <Input
              UNSAFE_width="120px"
              aria-label={createMessage(label)}
              data-testid={dataTestId}
              errorMessage={fieldState.error?.message}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={createMessage(placeholder)}
              size="md"
              value={value}
            />
          </div>
        );
      }}
      rules={validationRules}
    />
  );
}

export default function Form({
  defaultValues,
  isWorkflowScheduleSaving,
  triggerScheduleSave,
}: FormProps) {
  const [transformedCronString, setTransformedCronString] = useState("");
  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm<WorkflowSchedulerFormDataType>({
    mode: "onBlur",
    defaultValues,
  });

  const transformCronString = (
    value: Partial<WorkflowSchedulerFormDataType>,
  ) => {
    const {
      workflowScheduleDOM,
      workflowScheduleDOW,
      workflowScheduleHour,
      workflowScheduleMinute,
      workflowScheduleMonth,
    } = value;
    const cronString = `${workflowScheduleMinute} ${workflowScheduleHour} ${workflowScheduleDOM} ${workflowScheduleMonth} ${workflowScheduleDOW}`;

    try {
      const transformed = cronstrue.toString(cronString);

      setTransformedCronString(transformed);
    } catch (e) {
      setTransformedCronString(
        createMessage(WORKFLOW_SCHEDULE_TRANSFORMATION_FAILED),
      );
    }
  };
  const debouncedCronTransformation = useCallback(
    debounce(transformCronString, 500),
    [],
  );

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      debouncedCronTransformation(value);
    });

    transformCronString(defaultValues);

    return () => unsubscribe();
  }, [debouncedCronTransformation, defaultValues, watch]);

  const onSubmit: SubmitHandler<WorkflowSchedulerFormDataType> = (data) => {
    if (isValid) {
      triggerScheduleSave(data);
    }
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name={WokrflowSchedulerFormFields.timezone}
        render={({ field: { onChange, value } }) => (
          <Select className="w-60" onSelect={onChange} value={value}>
            {Object.entries(allTimeZoneOpts).map(([value, desc], idx) => {
              return (
                <Option key={idx} value={value}>
                  <Text kind="body-m">
                    {desc} - {value}
                  </Text>
                </Option>
              );
            })}
          </Select>
        )}
      />
      <div className="flex justify-between flex-grow">
        <InputFormComponent
          control={control}
          dataTestId="t--scheduler-input-minute"
          fieldName={WokrflowSchedulerFormFields.workflowScheduleMinute}
        />
        <InputFormComponent
          control={control}
          dataTestId="t--scheduler-input-hour"
          fieldName={WokrflowSchedulerFormFields.workflowScheduleHour}
        />
        <InputFormComponent
          control={control}
          dataTestId="t--scheduler-input-DOM"
          fieldName={WokrflowSchedulerFormFields.workflowScheduleDOM}
        />
        <InputFormComponent
          control={control}
          dataTestId="t--scheduler-input-month"
          fieldName={WokrflowSchedulerFormFields.workflowScheduleMonth}
        />
        <InputFormComponent
          control={control}
          dataTestId="t--scheduler-input-DOW"
          fieldName={WokrflowSchedulerFormFields.workflowScheduleDOW}
        />
      </div>
      <TransformedCronStringText
        data-testid="t--schedule-transformed-cron-string"
        isValid={isValid}
      >
        {isValid
          ? transformedCronString
          : createMessage(WORKFLOW_SCHEDULE_TRANSFORMATION_FAILED)}
      </TransformedCronStringText>
      <Button
        className="w-32"
        data-testid="t--scheduler-save-cta"
        isDisabled={!isValid}
        isLoading={isWorkflowScheduleSaving}
        size="md"
        type="submit"
      >
        {createMessage(WORKFLOW_SCHEDULE_FORM_CTA_SUBMIT)}
      </Button>
    </form>
  );
}
